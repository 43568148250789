import React from "react";

function Logo() {
  return (
    <svg
      id="logoSVG"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 653.48 169.48"
    >
      <title>quanto-custa-a-minha-hora</title>
      <path
        d="M73.84,96.47a8.36,8.36,0,0,0,2.46-.34,13.64,13.64,0,0,0,2.8-1.35v6.69a13.81,13.81,0,0,1-6.69,1.61,13,13,0,0,1-6.86-1.91,13.74,13.74,0,0,1-5.08-5.8,34.84,34.84,0,0,1-7.62.76q-26.26,0-26.26-29.48T52.84,37.19q26.34,0,26.34,29.48,0,18.89-10.84,25.66Q70.71,96.47,73.84,96.47ZM40.22,50.14q-4.07,5.08-4.07,16.52,0,11.61,4,16.85v-3.9a15.26,15.26,0,0,1,4.45-3.26A13.75,13.75,0,0,1,50.89,75a13.35,13.35,0,0,1,7.92,2.5,19.61,19.61,0,0,1,5.89,7,16.28,16.28,0,0,0,3.64-7,42.25,42.25,0,0,0,1.19-10.88q0-11.35-4-16.43T52.84,45.06Q44.28,45.06,40.22,50.14ZM52.84,88.77q2.62-.17,4.66-.51-3-6.27-8-6.27a11.18,11.18,0,0,0-7.79,3.22Q45.55,88.77,52.84,88.77Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M94.89,91.77q-5-4.36-5-12.75V52.35h8.89V77.76q0,5.42,2.41,8t7.5,2.58q5.08,0,7.5-2.58t2.41-8V52.35h8.55V79q0,8.39-4.83,12.75t-13.64,4.36Q99.85,96.14,94.89,91.77Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M169.93,54.76q4.87,3.35,4.79,10.8V85.89a8,8,0,0,1-5.08,8q-5.08,2.24-14.48,2.24-7.54,0-12.45-3.13t-4.91-10.5q0-6.94,4-10.42t11.39-3.47q7.62,0,12.7,3V67.59q0-8.64-10.42-8.64A25.29,25.29,0,0,0,148,60.1a17.74,17.74,0,0,0-6.18,3.18V55a21.91,21.91,0,0,1,6.9-2.54,42.62,42.62,0,0,1,9.36-1A20.37,20.37,0,0,1,169.93,54.76Zm-6.23,33.67a3,3,0,0,0,2.2-3V77.16a23.72,23.72,0,0,0-9.49-1.86q-4.83,0-7.33,1.69a5.73,5.73,0,0,0-2.5,5.08,5.88,5.88,0,0,0,2.84,5.34,12.41,12.41,0,0,0,6.73,1.78A25.8,25.8,0,0,0,163.71,88.43Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M196.61,52.64a47.75,47.75,0,0,1,10.46-1.23q8.89,0,13.13,3.73t4.23,11.6V94.87h-8.89V67.93q0-8.89-10.08-8.89a26.71,26.71,0,0,0-9.23,1.61V94.87h-8.89v-39A39.51,39.51,0,0,1,196.61,52.64Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M243,92.83q-3.3-3.13-3.3-8.89V59.72h-6.86V52.35h6.86V40.49h9.15V52.35h10.33v7.37H248.83V82.16a7.37,7.37,0,0,0,1.1,4.53q1.1,1.4,3.9,1.4a11.38,11.38,0,0,0,2.84-.38,9.82,9.82,0,0,0,2.5-1v7.88a16.81,16.81,0,0,1-7,1.35Q246.29,96,243,92.83Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M272.42,90.38q-5.13-5.76-5.12-16.77,0-22.28,20.33-22.28,10.25,0,15.42,5.72t5.17,16.56q0,10.84-5.34,16.69t-15.16,5.84Q277.54,96.14,272.42,90.38Zm26.89-16.77q0-7.45-2.8-11.14t-8.89-3.68q-6.18,0-8.85,3.77T276.1,73.61q0,7.29,2.75,11.14t8.77,3.85Q299.31,88.6,299.31,73.61Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M360.4,90.62q-6-5.17-6-16.69,0-11.86,5.89-17.15t14.95-5.29a34,34,0,0,1,13,2.54v8a30.64,30.64,0,0,0-11.77-2.71q-6.69,0-10,3.35t-3.3,10.63q0,7.62,3.22,11.14t10.33,3.52a26.72,26.72,0,0,0,11.6-2.8v8a31.74,31.74,0,0,1-13,2.71Q366.37,95.79,360.4,90.62Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M403.59,91.85q-5-4.36-5-12.75V52.42h8.89V77.83q0,5.42,2.41,8t7.5,2.58q5.08,0,7.5-2.58t2.41-8V52.42h8.55V79.1q0,8.39-4.83,12.75t-13.64,4.36Q408.55,96.21,403.59,91.85Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M453.4,95.36a40.27,40.27,0,0,1-6.65-2.54V84.44A38.22,38.22,0,0,0,453.69,88a20.4,20.4,0,0,0,7,1.14,9.68,9.68,0,0,0,5.72-1.48,4.54,4.54,0,0,0,2.08-3.85,3.76,3.76,0,0,0-1.69-3.39A26.65,26.65,0,0,0,461.57,78l-1.95-.68a25,25,0,0,1-9.44-4.15q-3.52-2.71-3.51-8.47A11.55,11.55,0,0,1,449,57.25a13.67,13.67,0,0,1,6-4.32,21.36,21.36,0,0,1,7.58-1.35,24.58,24.58,0,0,1,6.86,1,40.37,40.37,0,0,1,6.1,2.58v7.62a69,69,0,0,0-6.65-3.13,15.28,15.28,0,0,0-5.38-.85A10.78,10.78,0,0,0,457.93,60a4,4,0,0,0-2.12,3.56,4.21,4.21,0,0,0,2.12,3.94,30.49,30.49,0,0,0,6.61,2.5,52.62,52.62,0,0,1,6.73,2.29,11.29,11.29,0,0,1,4.4,3.6,11,11,0,0,1,1.91,6.82q-.09,6.78-5,10.12a20.52,20.52,0,0,1-11.9,3.35A28,28,0,0,1,453.4,95.36Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M494,92.91q-3.3-3.13-3.3-8.89V59.79h-6.86V52.42h6.86V40.56h9.15V52.42h10.33v7.37H499.85V82.23a7.37,7.37,0,0,0,1.1,4.53q1.1,1.4,3.9,1.4a11.38,11.38,0,0,0,2.84-.38,9.82,9.82,0,0,0,2.5-1v7.88a16.81,16.81,0,0,1-7,1.35Q497.31,96,494,92.91Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M550.71,54.83q4.87,3.35,4.79,10.8V86a8,8,0,0,1-5.08,8q-5.08,2.24-14.48,2.24-7.54,0-12.45-3.13t-4.91-10.5q0-6.94,4-10.42T534,68.68q7.62,0,12.7,3V67.67q0-8.64-10.42-8.64a25.29,25.29,0,0,0-7.45,1.14,17.75,17.75,0,0,0-6.18,3.18V55a21.91,21.91,0,0,1,6.9-2.54,42.62,42.62,0,0,1,9.36-1A20.36,20.36,0,0,1,550.71,54.83ZM544.49,88.5a3,3,0,0,0,2.2-3V77.24a23.71,23.71,0,0,0-9.49-1.86q-4.83,0-7.33,1.69a5.73,5.73,0,0,0-2.5,5.08,5.88,5.88,0,0,0,2.84,5.34,12.41,12.41,0,0,0,6.73,1.78A25.8,25.8,0,0,0,544.49,88.5Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M68.76,150.83q4.87,3.35,4.79,10.8V182a8,8,0,0,1-5.08,8q-5.08,2.24-14.48,2.24-7.54,0-12.45-3.13t-4.91-10.5q0-6.94,4-10.42T52,164.68q7.62,0,12.7,3v-4.07q0-8.64-10.42-8.64a25.29,25.29,0,0,0-7.45,1.14,17.74,17.74,0,0,0-6.18,3.18V151a21.91,21.91,0,0,1,6.9-2.54,42.62,42.62,0,0,1,9.36-1A20.37,20.37,0,0,1,68.76,150.83ZM62.54,184.5a3,3,0,0,0,2.2-3v-8.22a23.72,23.72,0,0,0-9.49-1.86q-4.83,0-7.33,1.69a5.73,5.73,0,0,0-2.5,5.08,5.88,5.88,0,0,0,2.84,5.34A12.41,12.41,0,0,0,55,185.26,25.8,25.8,0,0,0,62.54,184.5Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M169.55,151.21q4.11,3.73,4.11,11.6v28.12h-8.89V164q0-8.89-10.08-8.89a15.4,15.4,0,0,0-8.22,2.29,21.83,21.83,0,0,1,.59,5.42v28.12h-8.89V164q0-8.89-10.08-8.89a26,26,0,0,0-9,1.61v34.22h-8.89v-39a39.51,39.51,0,0,1,9.27-3.18A47.75,47.75,0,0,1,130,147.49q8.3,0,12.37,3.22a33.15,33.15,0,0,1,14.23-3.22Q165.44,147.49,169.55,151.21Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M186.19,133.6h9.49v8h-9.49Zm.25,15.08h8.89l.08,42.26h-9Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M217.49,148.72A47.75,47.75,0,0,1,228,147.49q8.89,0,13.13,3.73t4.23,11.6v28.12h-8.89V164q0-8.89-10.08-8.89a26.71,26.71,0,0,0-9.23,1.61v34.22h-8.89v-39A39.51,39.51,0,0,1,217.49,148.72Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M295.37,164.6v26.34h-8.89V165.7q0-5.17-2.46-7.88t-7.29-2.71a19.69,19.69,0,0,0-10,2.79l-.08,33h-8.81V130.46h8.89V151a26,26,0,0,1,12.53-3.47Q295.37,147.49,295.37,164.6Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M338.1,150.83q4.87,3.35,4.79,10.8V182a8,8,0,0,1-5.08,8q-5.08,2.24-14.48,2.24-7.54,0-12.45-3.13T306,178.57q0-6.94,4-10.42t11.39-3.47q7.62,0,12.71,3v-4.07q0-8.64-10.42-8.64a25.28,25.28,0,0,0-7.45,1.14,17.75,17.75,0,0,0-6.18,3.18V151a21.92,21.92,0,0,1,6.9-2.54,42.62,42.62,0,0,1,9.36-1A20.37,20.37,0,0,1,338.1,150.83Zm-6.23,33.67a3,3,0,0,0,2.2-3v-8.22a23.72,23.72,0,0,0-9.49-1.86q-4.83,0-7.33,1.69a5.73,5.73,0,0,0-2.5,5.08,5.88,5.88,0,0,0,2.84,5.34,12.41,12.41,0,0,0,6.73,1.78A25.8,25.8,0,0,0,331.88,184.5Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M425.61,164.65V191h-8.89V165.75q0-5.17-2.46-7.88T407,155.16A19.69,19.69,0,0,0,397,158l-.08,33h-8.81V130.51H397V151a26,26,0,0,1,12.54-3.47Q425.61,147.54,425.61,164.65Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M441.07,186.5q-5.13-5.76-5.12-16.77,0-22.28,20.33-22.28,10.25,0,15.42,5.72t5.17,16.56q0,10.84-5.34,16.69t-15.16,5.84Q446.19,192.26,441.07,186.5ZM468,169.73q0-7.45-2.79-11.14t-8.89-3.68q-6.18,0-8.85,3.77t-2.67,11.05q0,7.29,2.75,11.14t8.77,3.85Q468,184.72,468,169.73Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M487.1,152.36a37.6,37.6,0,0,1,8-3.13,39.24,39.24,0,0,1,11.26-1.69,13.64,13.64,0,0,1,5.76,1.1v7.11a41,41,0,0,0-5.67-.34,61.94,61.94,0,0,0-10.5.85V191H487.1Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M551.09,150.88q4.87,3.35,4.79,10.8V182a8,8,0,0,1-5.08,8q-5.08,2.24-14.48,2.24-7.54,0-12.45-3.13t-4.91-10.5q0-6.94,4-10.42t11.39-3.47q7.62,0,12.71,3v-4.07q0-8.64-10.42-8.64a25.28,25.28,0,0,0-7.45,1.14,17.75,17.75,0,0,0-6.18,3.18v-8.3a21.92,21.92,0,0,1,6.9-2.54,42.62,42.62,0,0,1,9.36-1A20.37,20.37,0,0,1,551.09,150.88Zm-6.23,33.67a3,3,0,0,0,2.2-3v-8.22a23.72,23.72,0,0,0-9.49-1.86q-4.83,0-7.33,1.69a5.73,5.73,0,0,0-2.5,5.08,5.88,5.88,0,0,0,2.84,5.34,12.41,12.41,0,0,0,6.73,1.78A25.8,25.8,0,0,0,544.87,184.55Z"
        transform="translate(-26.58 -25.79)"
      />
      <path
        d="M619.56,141.79q0-12.3,3.26-21a44.7,44.7,0,0,1,7.91-13.93,123.06,123.06,0,0,1,11.93-11.55,85,85,0,0,0,10.8-11.17A18.38,18.38,0,0,0,657,72.75q0-24.86-29.88-24.86a57.41,57.41,0,0,0-19.83,3.39,51,51,0,0,0-15.06,8.16V36.09q4-3,14.31-6.65a69,69,0,0,1,23.1-3.64,64,64,0,0,1,24.1,4.65,41.94,41.94,0,0,1,19,15.19q7.4,10.54,7.41,27.37,0,12.05-4.64,19.08t-13.93,15.06a114.37,114.37,0,0,0-10,9.79,37.67,37.67,0,0,0-6.4,10.67,39.79,39.79,0,0,0-2.64,15.19v8.54H619.56Zm-1.51,29.88h25.86v23.6H618.05Z"
        transform="translate(-26.58 -25.79)"
      />
    </svg>
  );
}

export default Logo;
